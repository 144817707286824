<!-- Settings -->
<template>
    <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="m-bot-15">       
          <div class="row">            
            <div class="col-sm-6 col-xs-12">
              <b-form-group class="mb-3">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    @keyup.enter.native="(filter)?fetchData(1):''"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                     <i class="fas fa-times" @click="filter =''"></i>
                     <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
             <div class="col-sm-6 col-xs-12 text-right">
               <b-button class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="createCategory"><i class="fa fa-plus"></i>Add category
               </b-button>
             </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column fixed type="index" width="50" label="SI">               
              </el-table-column>
              <el-table-column fixed align="center" label='Cat ID' prop="sku" sortable width="100" >
                <template slot-scope="scope">
                <span>{{scope.row.id}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Cat Image"  width="80" >
                <template slot-scope="scope">
                  <img v-if="scope.row.category_image" :src="image_url +scope.row.category_image" class="avatar">
              
                </template>
              </el-table-column>
              <el-table-column label="Category Name" sortable min-width="200" >
                <template slot-scope="scope">
                <span>{{(scope.row.label) ? scope.row.label : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column label="Parent Category" sortable  width="200" >
                <template slot-scope="scope">
                <span>{{(scope.row.parent_category) ? scope.row.parent_category.label : ""}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Location"   width="100" >
                <template slot-scope="scope">
                <span>{{scope.row.cat_location == 0 ? 'Store/Web': 'Store'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Dealer %"   width="70" >
                <template slot-scope="scope">
                <span>{{scope.row.dealer_margin ? scope.row.dealer_margin : ''}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Status" sortable  width="100" align="center">
                <template slot-scope="scope">
                  <el-switch :active-value="1" :inactive-value="0"  v-model="scope.row.cat_status"  @change="updateStatus(scope.row.id, scope.row.cat_status)" style="margin:0px"></el-switch>                                                      
                </template>
              </el-table-column>
              <el-table-column fixed="right" align="center" label="Actions" width="100">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">               
                      <el-dropdown-item :command="2" ><i class="fa fa-pen"></i>Edit</el-dropdown-item>                     
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
         <div class="row">
          <div class="col-sm-12">
           <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>  
      </div>
      </div>
        <v-dialog/>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllcategory, deleteCategory, changeCategoryStatus} from "@/api/product";

  export default {
    name: "list-categories",
    data() {
      return {
        list: [],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },       
        filter: null,
        filterOn: [],
        image_url :process.env.VUE_APP_BASE_IMAGE_URL
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Category", route: "/product/categories" },        
      ]);
    },
    created(){
      this.fetchData(1);
    },
    methods:{
      updateStatus(cat_id,status){        
        changeCategoryStatus(cat_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })},

      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
       if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        getAllcategory(params).then(response => {
          this.list = response.data.data.data
           this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
        })
      },
      createCategory(){
        this.$router.push({path:'/product/add-category/'})
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      handlePageChange(p){
        this.fetchData(p)
      },
      handlePerPageChange(){
        this.fetchData(1)
      },
      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/product/category/'+row.id})
          }
          else if (evt === 2){
            this.$router.push({path:'/product/edit-category/'+row.id})
          }
          else if (evt === 3){
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
                deleteCategory(row.id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }
        },
    }
  }
</script>
<style scoped>
 .avatar {
    width: 50px;
    height: 40px;
    display: block;
  }
</style>